<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton
          id="save-button"
          name="save-button"
          classProp="primary"
          :isDisabled="!isDirty"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
        <VasionButton
          id="cancel-button"
          class="last-btn"
          name="cancel-button"
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>

      </div>
    </div>
    <div id="add-to-workflow-details">
      <AutomationEngineSharedHeader
        ref="header"
        :nameLabel="'ADD TO WORKFLOW NAME'"
        @permissions="showMainSection = false"
        @general-automation="showMainSection = true"
        @dirty="isDirty = true"
      />
      <div v-if="showMainSection" id="add-to-workflow" class="collapsable-section">
        <h2 class="subheader">
          Build Add to Workflow
        </h2>
        <VasionButton
          name="collapse-section"
          class="collapse-section"
          :icon="showAddToWorkflow ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
          title="Collapse Section"
          @vasionButtonClicked="toggleCollapsableSection('add-to-workflow')"
        />
        <div v-show="showAddToWorkflow">
          <div class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="attributeForm"
              class="row-field"
              :dataArray="attributeFormList"
              width="396"
              :title="`${$formsLabel.toUpperCase()}`"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionInput
              id="process-folder"
              v-model="folderName"
              class="row-field"
              title="PROCESS FOLDER"
              inputType="top-white"
              name="process-folder"
              placeholder=""
              :width="'396'"
            />
            <VasionButton
              id="browse-button"
              class="browse-button"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog"
            >
              Browse
            </VasionButton>
            <VasionCheckbox
              id="new-subfolder"
              name="new-subfolder"
              class="export-checkbox align-with-inputs"
              :checked="includeSubfolders"
              @change="toggleCheckbox('includeSubfolders')"
            >
              Include Subfolders
            </VasionCheckbox>
          </div>
          <div class="row">
            <VasionCheckbox
              id="add-single-workFlow"
              name="add-single-workFlow"
              class=""
              :checked="addSingleWorkflow"
              @change="onAddSingleWorkflowChange"
            >
              Add to Single Workflow
            </VasionCheckbox>
          </div>
          <div class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="workflow"
              class="row-field"
              :dataArray="workflowList"
              :isDisabled="!addSingleWorkflow"
              width="396"
              title="ADD TO WORKFLOW"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              v-slot="slotItem"
              v-model="fieldName"
              class="row-field"
              :dataArray="attributeFieldList"
              width="396"
              title="FIELD NAME"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              :isDisabled="addSingleWorkflow"
              @input="isDirty = true; onFieldNameChange()"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
        </div>
      </div>
      <div v-if="showMainSection" id="field-comparison" class="collapsable-section">
        <h2 class="subheader">
          Field Comparison
        </h2>
        <VasionButton
          name="collapse-section"
          class="collapse-section"
          :icon="showFieldComparison ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
          title="Collapse Section"
          @vasionButtonClicked="toggleCollapsableSection('field-comparison')"
        />
        <div v-show="showFieldComparison">
          <div
            v-for="(step, index) in fieldComparisonData"
            v-show="attributeForm"
            :key="index"
            class="row"
          >
            <VasionDropList
              v-if="addSingleWorkflow"
              v-slot="slotItem"
              v-model="step.attributeField"
              class="row-field"
              :dataArray="attributeFieldList"
              width="396"
              title="FIELD"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              v-if="addSingleWorkflow"
              v-show="step.attributeField"
              v-slot="slotItem"
              v-model="step.compareType"
              class="row-field"
              :dataArray="step.attributeField.type === 1 || step.attributeField.type === 2 ? comparisonFormStringAndNumeric : comparisonFormDates"
              width="396"
              title="COMPARE"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionInput
              v-if="addSingleWorkflow && step.attributeField.type === 1"
              id="value-string"
              v-model="step.compareValue"
              class="row-field"
              title="VALUE"
              inputType="top-white"
              name="value-string"
              placeholder="..."
              :width="'396'"
              @input="isDirty = true"
            />
            <VasionInput
              v-if="addSingleWorkflow && step.attributeField.type === 2"
              id="value-number"
              v-model="step.compareValue"
              class="row-field"
              inputType="top-white"
              type="number"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              width="396"
              title="VALUE"
              name="value-number"
              @input="isDirty = true"
            />
            <VasionDateTimeField
              v-if="addSingleWorkflow && step.attributeField.type === 3"
              v-model="step.compareValue"
              label="START DATE"
              type="date"
              class="row-field"
              width="396px"
              @input="isDirty = true"
            />
            <VasionDropList
              v-if="!addSingleWorkflow && fieldName"
              v-slot="slotItem"
              v-model="step.compareType"
              class="row-field"
              :dataArray="fieldName.type === 1 || fieldName.type === 2 ? comparisonFormStringAndNumeric : comparisonFormDates"
              width="396"
              title="COMPARE"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionInput
              v-if="!addSingleWorkflow && fieldName && fieldName.type === 1"
              id="value-string"
              v-model="step.compareValue"
              class="row-field"
              title="VALUE"
              inputType="top-white"
              name="value-string"
              placeholder="..."
              :width="'396'"
              @input="isDirty = true"
            />
            <VasionInput
              v-if="!addSingleWorkflow && fieldName && fieldName.type === 2"
              id="value-number"
              v-model="step.compareValue"
              class="row-field"
              inputType="top-white"
              type="number"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              width="396"
              title="VALUE"
              name="value-number"
              @input="isDirty = true"
            />
            <VasionDateTimeField
              v-if="!addSingleWorkflow && fieldName && fieldName.type === 3"
              v-model="step.compareValue"
              label="START DATE"
              type="date"
              class="row-field"
              width="396px"
              @input="isDirty = true"
            />
            <VasionDropList
              v-if="!addSingleWorkflow && fieldName"
              v-slot="slotItem"
              v-model="step.workflow"
              class="row-field"
              :dataArray="workflowList"
              width="396"
              title="WORKFLOW"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionButton
              name="add-complete-email-template-button"
              :icon="'VasionDeleteIcon'"
              title="Remove Dynamic Field"
              class="configure-template-icon-button align-with-inputs"
              @vasionButtonClicked="removeIndexField(index)"
            />
          </div>
          <div class="row">
            <VasionButton
              id="add-step"
              name="add-step"
              class="add-step"
              :classProp="'secondary'"
              @vasionButtonClicked="addField()"
            >
              Add Field
            </VasionButton>
          </div>
        </div>
      </div>
    </div>
    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <div class="folder-dialog-browse">
        <VasionFolders />
      </div>
      <div class="align-right">
        <VasionButton
          id="browse-cancel"
          classProp="secondary"
          @vasionButtonClicked="toggleBrowseFolderDialog()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="browse-ok"
          classProp="primary"
          @vasionButtonClicked="folderDialogOK"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionGeneralModal
      id="field-name-change"
      :modalType="'message'"
      :buttonGroup="'center'"
      :confirmButtonText="'Yes'"
      :rejectButtonText="'No'"
      :message="'Are you sure you want to change the field name? This will clear the Field Comparison Rules'"
      :sync="showFieldNameModal"
      @confirmButtonClick="nameFieldChangeConfirmation"
      @noButtonClick="nameFieldChangeCancellation"
    />

    <VasionGeneralModal
      id="add-single-wf-unchecked"
      :modalType="'message'"
      :buttonGroup="'center'"
      :confirmButtonText="'Yes'"
      :rejectButtonText="'No'"
      :message="'Are you sure you want to uncheck the Add to Single Workflow? This will clear the COMPARE field from the Field Comparisons. And you must select a FIELD NAME.'"
      :sync="showAddSingleWFModal"
      @confirmButtonClick="addSingleWFConfirmation"
      @noButtonClick="addSingleWFCancellation"
    />

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
/* eslint-disable valid-typeof */
/* eslint-disable no-mixed-operators */

import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';

export default {
  name: 'EditAddToWorkflow',
  components: {
    AutomationEngineSharedHeader,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      addSingleWorkflow: true,
      attributeForm: '',
      fieldComparisonData: [],
      fieldName: '',
      fieldNameBuffer: '',
      folderId: 0,
      folderName: '',
      ignoreWatch: true,
      includeSubfolders: false,
      isDirty: false,
      schedulerId: 0,
      schedulerTitle: '',
      showAddSingleWFModal: false,
      showAddToWorkflow: false,
      showBrowseFoldersDialog: false,
      showFieldComparison: false,
      showFieldNameModal: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbar: false,
      snackbarImage: false,
      snackbarText: '',
      snackbarTitle: '',
      workflow: '',
      workflowData: [],
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    attributeFieldList() { return this.$store.state.common.indexFields },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    comparisonFormDates() {
        return this.$store.state.automationEngine.comparisonForm.dateTypes.map((c, index) => {
            return {
                name: c,
                value: index,
            }
        })
    },
    comparisonFormStringAndNumeric() {
        return this.$store.state.automationEngine.comparisonForm.stringAndNumericTypes.map((c, index) => {
            return {
                name: c,
                value: index,
            }
        })
    },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    workflowList() {
        return this.workflowData.map((w) => {
            return {
                name: w.sName,
                value: w.iID,
            }
        })
    },
  },
  watch: {
    attributeForm: async function () {
      if (!this.ignoreWatch && this.attributeForm && this.attributeForm.value > 0) {
        await this.$store.dispatch('common/getIndexFieldsForForm', this.attributeForm.value)
      }
    },
  },
  async created() {
    await this.$store.dispatch('automationEngine/clearActiveScheduler')
    await Promise.all([
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('automationEngine/getSchedulerFieldComparisonTypes'),
    ])
    this.workflowData = await this.$store.dispatch('workflow/getWorkflowAdminList')
    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getAddToWorkflowScheduler', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        if (this.activeScheduler.attributeFormID && this.activeScheduler.attributeFormID > 0) {
          await Promise.all([
            this.$store.dispatch('common/getIndexFieldsForForm', this.activeScheduler.attributeFormID),
            this.$store.dispatch('automationEngine/getSchedulerFieldComparisonTypes'),
          ])
        }
        this.setSchedulerValues()
      }
    }
    this.setHeaderDetails(this.schedulerId, this.activeScheduler?.runDetails)

    this.schedulerTitle = this.schedulerId && this.schedulerId > 0 && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled Add to Workflow'
    this.ignoreWatch = false
  },
  methods: {
    addField() {
      if (this.attributeForm === '') {
        return
      }
      this.isDirty = true
      this.fieldComparisonData.push({
          attributeField: '',
          compareType: '',
          compareValue: '',
          workflow: ''
      })
    },
    addSingleWFCancellation() {
      this.showAddSingleWFModal = false
      this.toggleCheckbox('addSingleWorkflow')
    },
    addSingleWFConfirmation() {
      const workflow = this.workflowList.find(a => a.value === this.workflow.value)
      this.fieldComparisonData.map((value, index) => {
        this.fieldComparisonData[index].compareType = ''
        this.fieldComparisonData[index].workflow = workflow
      })
      this.showAddSingleWFModal = false      
    },
    buildComparisonArray() {
      return this.fieldComparisonData.map((c) => {
        return {
          fieldName: this.addSingleWorkflow ? c.attributeField.value : null,
          comparisonValue: c.compareValue,
          comparisonType: c.compareType.name,
          wfid: !this.addSingleWorkflow ? c.workflow.value : null
        }
      })
    },
    cancel() { this.$router.push({ name: 'AddToWorkflow' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK() {
      if (this.selectedFolder
        && this.selectedFolder.isSelected === true) {
        this.isDirty = true
        this.folderId = this.selectedFolder.item.FolderId
        this.folderName = this.selectedFolder.item.Name
      } else {
        this.folderId = null
        this.folderName = ''
      }

      this.toggleBrowseFolderDialog()
    },
    hideSnackbar() { this.showSnackbar = false },
    nameFieldChangeCancellation() {
      this.showFieldNameModal = false
      this.fieldName = this.fieldNameBuffer
    },
    nameFieldChangeConfirmation() {
      this.fieldComparisonData.map((value, index) => {
          this.fieldComparisonData[index].compareType =  ''
          this.fieldComparisonData[index].compareValue =  ''
        })
      this.showFieldNameModal = false
      this.fieldNameBuffer = this.fieldName
    },
    onAddSingleWorkflowChange() {
      this.toggleCheckbox('addSingleWorkflow')
      if (this.fieldComparisonData.length > 0) { 
        if (!this.addSingleWorkflow && this.workflow) {
          this.showAddSingleWFModal = true
        }
        
        if (this.addSingleWorkflow) {
          this.fieldComparisonData = []
          this.fieldName = ''
        }
      }
    },
    onFieldNameChange() {
      if (this.fieldComparisonData.length > 0 && this.fieldNameBuffer) {
        if (this.fieldNameBuffer?.type !== this.fieldName?.type) {
          this.showFieldNameModal = true
        }
      } else {
        this.fieldNameBuffer = this.fieldName
      }
    },
    removeIndexField(id) {
      this.isDirty = true
      this.fieldComparisonData.splice(id, 1)
    },
    async save() {
      let comparisonFieldList = ''
      if (this.fieldComparisonData.length > 0) {
         comparisonFieldList = this.buildComparisonArray()
      }
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          folderID: this.folderId,
          folderName: this.folderName,
          wfid: this.workflow.value,
          attributeFormID: this.attributeForm.value,
          checkSubfolders: this.includeSubfolders,
          sIndexFieldName: this.fieldName?.value,
          comparisonFields: comparisonFieldList,
        }

        if (!await this.$store.dispatch('automationEngine/saveAddToWorkflowScheduler', payload)) {
          const errorText = 'There was an error saving the add-to-workflow.'
          this.snackbarTitle = 'ERROR'
          this.snackbarText = errorText
          this.showSnackbar = true
          this.snackbarImage = false
          console.warn(errorText)
        } else {
          this.isDirty = false
          this.snackbarTitle = 'SUCCESS'
          this.snackbarText = 'Add-to-workflow saved successfully!'
          this.showSnackbar = true
          this.snackbarImage = true

          setTimeout(() => {
            this.$router.push({ name: 'AddToWorkflow' })
          }, 1500)
        }
      }
    },
    setHeaderDetails(id, details) {
      const { header } = this.$refs
      if (id > 0) {
        header.setValues(details)
      } else {
        header.setValues()
      }
    },
    setSchedulerValues() {
      this.schedulerId = this.activeScheduler.runDetails.schedulerID
      this.folderId = this.activeScheduler.folderID
      this.folderName = this.activeScheduler.folderName
      this.includeSubfolders = this.activeScheduler.checkSubfolders
      this.addSingleWorkflow = this.activeScheduler.sIndexFieldName === "" ? true : false
      this.attributeForm = this.attributeFormList.find(a => a.value === this.activeScheduler.attributeFormID) || ''
      this.workflow = this.workflowList.find(a => a.value === this.activeScheduler.wfid)
      this.fieldName = this.attributeFieldList.find(a => a.value === this.activeScheduler.sIndexFieldName)
      this.fieldNameBuffer = this.fieldName
      if (this.activeScheduler.comparisonFields.length > 0) {
        this.activeScheduler.comparisonFields.forEach((c) => {
          const attField = c.fieldName ? this.attributeFieldList.find(a => a.value === c.fieldName) : null
          const fieldType = c.fieldName ? attField.type : this.fieldName.type
          const compTypeList = fieldType === 1 || fieldType === 2 ? this.comparisonFormStringAndNumeric : this.comparisonFormDates
          const workflowField = c.wfid ? this.workflowList.find(a => a.value === c.wfid) : null
          this.fieldComparisonData.push({
            attributeField: attField,
            compareType: compTypeList.find(a => a.name === c.comparisonType),
            compareValue: c.comparisonValue,
            workflow: workflowField,
            })
        })
      }
    },
    toggleBrowseFolderDialog() { this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog },
    toggleCheckbox(id) {
      this[id] = !this[id]
      this.isDirty = true
    },
    toggleCollapsableSection(section) {
      switch (section) {
        case 'add-to-workflow':
          this.showAddToWorkflow = !this.showAddToWorkflow
          break;
        case 'field-comparison':
          this.showFieldComparison = !this.showFieldComparison
          break;
        default:
          this.showAddToWorkflow = false
          break;
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let error = false
      let errorText = ''

      await header.validate()
      if (header.errors !== '') {
        error = true
        errorText = header.errors
      }
      if (this.addSingleWorkflow && (!this.workflow || !this.folderName)) {
          error = true
          errorText = !this.folderName ? 'Please select a process folder' : 'Please select an Add To Workflow'
      }
      if (!this.addSingleWorkflow && (!this.attributeForm || !this.fieldName)) {
          error = true
          errorText = !this.attributeForm ? 'Please select an Object' : 'Please select a Field Name'
      }

      if (error) {
        this.snackbarTitle = 'ERROR'
        this.snackbarText = errorText
        this.showSnackbar = true
        this.snackbarImage = false
        console.warn(errorText)
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

#add-to-workflow-details {
  width: 100%;
  .row {
    width: 100%;
    margin: 13px 0;
    display: flex;
    align-items: center;

    .section-title {
      @include SECTION-HEADER;
      color: $grey-400;
      display: block;
      margin: 5px 0;
    }

    .subheader {
      @include SubHeadline;
    }

    .row-field {
      margin: 0 14px 5px 0;

      &.checkbox {
        width: 390px;
      }
    }

    .browse-input {
      margin-right: 5px;
    }

    .vasion-button {
      &.browse-button {
        button {
          margin-top: 22px;
          margin-left: 0;
          margin-right: 13px;
        }
      }
    }

    .scheduler-checkbox {
      width: 408px;
    }

    .vasion-checkbox {
      margin-top: 13px;
      margin-left: 10px;
    }
  }

  .collapsable-section {
    float: left;
    margin-top: 20px;
    width: 100%;

    .subheader {
      @include SubHeadline;
      width: 225px;
      display: block;
      float: left;
      margin-top: 5px;
    }

    .subtitle {
      @include Subtitle;
    }
  }
}

#browse-button {
  top: 7px;
  position: relative;
}

#browse-folders-dialog {
  .folder-dialog-browse {
    width: 400px;
    height: 300px;
    overflow: auto;
  }

  .align-right {
    text-align: right;
  }
}
</style>
